import * as yup from 'yup';
import './customYupValidations';
import moment from 'moment';

export const validationScheme = {
  policyDisclaimer: yup.object().shape({}),
  typeOfInsurance: yup.object().shape({
    InsuranceType: yup.string().required('Type of insurance is required.'),
  }),
  registrationNumber: yup.object().shape({
    RegistrationNumber: yup
      .string()
      .required('Registration number is required.')
      .matches(
        /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/,
        // /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'This is not standard UK registration number format.'
      ),
  }),
  makeOfTheVan: yup.object().shape({
    VehicleMake: yup.string().required('Make of the van is required.'),
  }),
  vanModel: yup.object().shape({
    VanModelName: yup.string().required('Model of the van is required.'),
  }),
  aboutYourVehicle: yup.object().shape({
    YearOfRegistration: yup
      .number()
      .min(1900, 'Year of registration cannot be before 1900.')
      .max(
        new Date().getFullYear(),
        'Year of registration cannot be after current year.'
      )
      .required('Year of registration is required.'),
    EngineType: yup.string().required('Engine size and type is required.'),
    // ConfirmVehicle: yup.string().required('Vehicle confirmation is required.'),
    NumberOfSeats: yup
      .number()
      .min(1, 'The vehicle cannot have less than 1 seat.')
      .max(9, 'The vehicle cannot have more than 9 seats.')
      .required('Number of seats is required.'),
    VehicleWorth: yup
      .number()
      .min(1, 'Worth of the vehicle is required.')
      .required('Worth of the vehicle is required.'),
    Modified: yup
      .string()
      .required('Answer about vehicle modification is required.'),
    BodyType: yup.string().required('Van body type is required.'),
    AlreadyBought: yup
      .string()
      .required('Answer about buying the vehicle is required.'),
    TimeOfPurchase: yup.string().when('AlreadyBought', {
      is: (value) => value === 'true',
      then: yup
        .string()
        .isMonth('Time of purchase must have a valid date value.')
        .required('Time of purchase is required field.')
        .test(
          'isInvalidTime',
          'Unfortunately we cannot provide a quote as the time of purchase is out of accepted range.',
          (value) =>
            moment().diff(moment(value, 'MM/YYYY', true), 'years') <= 120
        )
        .test(
          'isAfterCurrent',
          'Unfortunately we cannot provide a quote as the time of purchase is after the current month.',
          (value) => moment().isSameOrAfter(moment(value, 'MM/YYYY'), 'month')
        ),
      otherwise: yup.string(),
    }),
  }),
  vehicleUsage: yup.object().shape({
    OwnerAndKeeper: yup
      .string()
      .required(
        'Answer about the owner and the registered keeper is required.'
      ),
    Owner: yup.string().when('OwnerAndKeeper', {
      is: (value) => value === 'false',
      then: yup.string().required('The owner of the van is required.'),
      otherwise: yup.string(),
    }),
    RegisteredKeeper: yup.string().when('OwnerAndKeeper', {
      is: (value) => value === 'false',
      then: yup.string().required('The registered keeper is required.'),
      otherwise: yup.string(),
    }),
    OvernightLocation: yup.string().required('Overnight location is required.'),
    VehicleUse: yup.string().required('Vehicle usage is required.'),
    AnnualMileage: yup.string().required('Your annual mileage is required.'),
  }),
  vanPersonalInfo: yup.object().shape({
    Policyholder: yup.object().shape({
      Title: yup.string().required('Your title is required.'),
      Name: yup.string().required('Your name is required.'),
      DateOfBirth: yup
        .string()
        .isDate('Date of Birth must have a valid date value.')
        .required('Date of birth is required field.')
        .test(
          'isAdult',
          'Unfortunately we cannot provide a quote as you are under the age of 18.',
          (value) =>
            moment().diff(moment(value, 'DD/MM/YYYY', true), 'years') >= 18
        )
        .test(
          'isInvalidAge',
          'Unfortunately we cannot provide a quote as your age is out of accepted range.',
          (value) =>
            moment().diff(moment(value, 'DD/MM/YYYY', true), 'years') <= 120
        ),
      BornInTheUK: yup
        .string()
        .required('Answer about your place of birth is required.'),
      RelationshipStatus: yup
        .string()
        .required('Answer about your relationship status is required.'),
      NumberOfChildren: yup
        .string()
        .required('Number of children aged 16 or under is required.'),
      Address: yup.object().shape({
        addressLine1: yup
          .string()
          .required('Address Line 1 is required field.'),
        postTown: yup.string().required('City is required field.'),
        postcode: yup.string().required('Postcode is required field.'),
      }),
      Phone: yup.string().required('Phone is required field.'),
      Email: yup
        .string()
        .email('Email must be a valid email.')
        .required('Email is required field.'),
      VehicleAtThisAddress: yup
        .string()
        .required('Answer about the location of the van is required.'),
      HomeOwner: yup
        .string()
        .required('Answer about being a home owner is required.'),
      EmploymentStatus: yup.string().required('Select your employment status.'),
      Profession: yup.string().required('Your profession is a required field.'),
      Industry: yup
        .string()
        .required('The industry you work in is a required field.'),
      AnotherJob: yup
        .string()
        .required('Answer about having another job is required.'),
      OtherProfession: yup.string().when('AnotherJob', {
        is: (value) => value === 'true',
        then: yup.string().required('Your profession is a required field.'),
        otherwise: yup.string(),
      }),
      OtherIndustry: yup.string().when('AnotherJob', {
        is: (value) => value === 'true',
        then: yup
          .string()
          .required('The industry you work in is a required field.'),
        otherwise: yup.string(),
      }),
    }),
  }),
  licenceDrivingHistory: yup.object().shape({
    DrivingLicence: yup
      .string()
      .required('Type of driving licence is required.'),
    YearsDriving: yup
      .string()
      .required('The number of years you have held you licence is required.'),
    LicenceNumber: yup.string(),
    AdvancedMotorists: yup
      .string()
      .required('Answer about being a member of IAM is required.'),
    MedicalConditions: yup
      .string()
      .required('Answer about your medical conditions is required.'),
    AccidentsClaimsLosses: yup
      .string()
      .required(
        'Answer about having any accidents, claims or losses is required.'
      ),
    DrivingRelatedConvictions: yup
      .string()
      .required('Answer about driving related convictions is required.'),
    NonMotoringConvictions: yup
      .string()
      .required('Answer about non-motoring-related convictions is required.'),
  }),
  additionalDrivers: yup.object().shape({
    AdditionalDrivers: yup
      .string()
      .required('Answer about additional drivers is required.'),
  }),
  voluntaryExcess: yup.object().shape({
    VoluntaryExcess: yup
      .string()
      .required('Amount of voluntary excess is required.'),
  }),
  vanNCD: yup.object().shape({
    VanNCD: yup.string().required('Number of years of NCD is required.'),
  }),
  finalDetails: yup.object().shape({
    OtherVehicles: yup
      .string()
      .required('Answer about other vehicles is required.'),
    NumberOfVehicles: yup
      .number()
      .required('Number of other vehicles is required.')
      .min(0, 'The number cannot be less than 0'),
    MonthlyPayment: yup
      .string()
      .required('Answer about monthly payment is required.'),
    InsuranceDeclined: yup
      .string()
      .required(
        'Answer about drivers having their insurance declined is required.'
      ),
  }),
  typeOfFleet: yup.object().shape({
    FleetType: yup.string().required('Type of fleet is required.'),
  }),
  fleetBusinessInfo: yup.object().shape({
    TradingName: yup.string().required('Trading name is required.'),
    BusinessAddress: yup.object().shape({
      addressLine1: yup.string().required('Address Line 1 is required field.'),
      postTown: yup.string().required('City is required field.'),
      postcode: yup.string().required('Postcode is required field.'),
    }),
    TradeOccupation: yup.string().required('Trade Occupation is required.'),
    YearsTrading: yup.number().required('Years in business is required.'),
  }),
  typeOfCover: yup.object().shape({
    TypeOfCover: yup.string().required('Type of cover is required.'),
  }),
  fleetDriverType: yup.object().shape({
    VehicleDriver: yup.string().required('Driver type is required.'),
  }),
  ratedFleet: yup.object().shape({
    AreFleetRated: yup.string().required('This question is required.'),
  }),
  personalInfo: yup.object().shape({
    Policyholder: yup.object().shape({
      Title: yup.string().required('Title is required field.'),
      Name: yup.string().required('First name is required field.'),
      Phone: yup.string().required('Phone is required field.'),
      Email: yup
        .string()
        .email('Email must be a valid email.')
        .required('Email is required field.'),
      Address: yup.object().shape({
        addressLine1: yup
          .string()
          .required('Address Line 1 is required field.'),
        postTown: yup.string().required('City is required field.'),
        postcode: yup.string().required('Postcode is required field.'),
      }),
      DateOfBirth: yup
        .string()
        .isDate('Date of Birth must have a valid date value.')
        .required('Date of birth is required field.')
        .test(
          'isAdult',
          'Unfortunately we cannot provide a quote as you are under the age of 18.',
          (value) =>
            moment().diff(moment(value, 'DD/MM/YYYY', true), 'years') >= 18
        )
        .test(
          'isInvalidAge',
          'Unfortunately we cannot provide a quote as your age is out of accepted range.',
          (value) =>
            moment().diff(moment(value, 'DD/MM/YYYY', true), 'years') <= 120
        ),
    }),
  }),
  coverStartDate: yup.object().shape({}),
  fleetVehicles: yup.object().shape({
    NumberOfCars: yup
      .number()
      .typeError('This need to be a number.')
      .min(0, 'Cannot select less than zero.'),
    NumberOfVans: yup
      .number()
      .typeError('This need to be a number.')
      .min(0, 'Cannot select less than zero.'),
    NumberOfMotorbikes: yup
      .number()
      .typeError('This need to be a number.')
      .min(0, 'Cannot select less than zero.'),
    NumberOfOtherVehicles: yup
      .number()
      .typeError('This need to be a number.')
      .min(0, 'Cannot select less than zero.'),
    NumberOfPickUps: yup
      .number()
      .nullable()
      .min(0, 'Cannot select less than zero.'),
    NumberOfLorriesTrucks: yup
      .number()
      .nullable()
      .min(0, 'Cannot select less than zero.'),
    NumberOfTaxis: yup
      .number()
      .nullable()
      .min(0, 'Cannot select less than zero.'),
    NumberOfMinibuses: yup
      .number()
      .nullable()
      .min(0, 'Cannot select less than zero.'),
  }),
  truckType: yup.object().shape({
    TruckType: yup.string().required(),
  }),
  aboutTruck: yup.object().shape({
    VehicleWeight: yup.string().required('Vehicle weight is required.'),
    VehicleModel: yup.string().required('Vehicle model is required.'),
    VehicleMake: yup.string().required('Vehicle make is required.'),
    YearOfManufactured: yup
      .number()
      .required('Year of manufacture is required.')
      .min(1900, 'Year of manufacture cannot be built before 1900.')
      .max(
        new Date().getFullYear(),
        'Year of manufacture cannot be built after current year.'
      ),
    VehicleWorth: yup.number().required('Worth of vehicle is required.'),
  }),
  drivingLicenceInfo: yup.object().shape({
    DrivingLicenceType: yup
      .string()
      .required('Driving Licence type is required.'),
    LicenceYearsHeld: yup
      .string()
      .required('Years of licence hold is required.'),
  }),
  noClaimInfo: yup.object().shape({
    YearsOfNoClaim: yup.string().required('Years of no claim is required.'),
  }),
  aboutTruckTransport: yup.object().shape({
    MainUseOfVehicle: yup.string().required('Main use of vehicle is required.'),
    OperateType: yup.string().required('Operate type is required.'),
    OperatePostcode: yup.object().shape({
      addressLine1: yup.string().required('Address Line 1 is required field.'),
      postTown: yup.string().required('City is required field.'),
      postcode: yup.string().required('Postcode is required field.'),
    }),
    AnyHazardousGoods: yup.string().required('This question is required.'),
  }),
  minibusMake: yup.object().shape({
    VehicleMake: yup.string().required('Minibus make is required.'),
  }),
  aboutMinibus: yup.object().shape({
    VehicleModel: yup.string().required('Vehicle model is required.'),
    YearOfManufactured: yup
      .number()
      .required('Year of manufacture is required.')
      .min(1900, 'Year of manufacture cannot be before 1900.')
      .max(
        new Date().getFullYear(),
        'Year of manufacture cannot be after current year.'
      ),
    EngineType: yup.string().required('Engine type and size is required.'),
    VehicleWorth: yup.number().required('Worth of vehicle is required.'),
  }),
  mainMinibusUse: yup.object().shape({
    MainMinibusUse: yup.string().required('Minibus use is required.'),
  }),
  minibusNumberOfPassengers: yup.object().shape({
    NumberOfPassengers: yup
      .string()
      .required('Number of passengers is required.'),
  }),
  minibusDrivingLicence: yup.object().shape({
    VehicleDriver: yup.string().required('Driver type is required.'),
    DrivingLicenceYearsOld: yup
      .string()
      .required('Driver licence type is required.'),
  }),
  coverPeriod: yup.object().shape({
    CoverPeriod: yup.string().required('Cover period is required.'),
  }),
  minibusNoClaimBonus: yup.object().shape({
    MinibusNoClaimBonus: yup.string().required('No claim bonus is required.'),
  }),
  privateCarNoClaimBonus: yup.object().shape({
    PrivateCarNoClaimBonus: yup
      .string()
      .required('No claim bonus is required.'),
  }),
  aboutMinibusClaim: yup.object().shape({
    ClaimLastFiveYears: yup
      .string()
      .required('Claim in last five year is required.'),
    MotoringConviction: yup
      .string()
      .required('You need to answer if you were convicted.'),
  }),
  numberOfBuses: yup.object().shape({
    NumberOfBuses: yup
      .string()
      .required('Choose how many buses/coaches you have.'),
  }),
  mainBusUse: yup.object().shape({
    BusUse: yup.array().min(1, 'You need to select at least one use of bus.'),
  }),
  noClaimBonus: yup.object().shape({
    NoClaimBonus: yup
      .string()
      .required('Number of years of no claim bonus is required.'),
  }),
  aboutBus: yup.object().shape({
    VehicleMake: yup.string().required('Make of bus/coach is required.'),
    VehicleModel: yup.string().required('Model of bus/coach is required.'),
    VehicleWorth: yup.number().required('Estimation of bus/coach is required.'),
    MaxNumberOfPassengers: yup
      .string()
      .required('Maximum number of passengers  is required.'),
  }),
  aboutYourTaxi: yup.object().shape({
    VehicleMake: yup.string().required('Make of the vehicle is required.'),
    TaxiModel: yup.string().required('Model of the vehicle is required.'),
    YearOfRegistration: yup
      .number()
      .min(1900, 'Year of registration cannot be before 1900.')
      .max(
        new Date().getFullYear(),
        'Year of registration cannot be after current year.'
      )
      .required('Year of registration is required.'),
    EngineType: yup.string().required('Engine size and type are required.'),
    TaxiWorth: yup.number().required('Worth of the vehicle is required.'),
  }),
  typeOfTaxi: yup.object().shape({
    TypeOfTaxi: yup.string().required('Type of vehicle is required.'),
    TaxiUsage: yup.string().required('Main taxi use is required.'),
    UberDriver: yup
      .string()
      .required('Answer about being an Uber driver is required.'),
  }),
  maxPassengers: yup.object().shape({
    MaxNumber: yup
      .string()
      .required('Maximum number of passengers is required.'),
  }),
  taxiLicence: yup.object().shape({
    LicenceTime: yup
      .string()
      .required('Years of holding a licence are required.'),
  }),
  taxiBadge: yup.object().shape({
    TaxiBadge: yup.string().required('Local authority or council is required.'),
  }),
  taxiNCD: yup.object().shape({
    TaxiNCD: yup.string().required('Number of years of NCD is required.'),
  }),
  privateCarNCD: yup.object().shape({
    PrivateCarNCD: yup.string().required('Number of years of NCD is required.'),
  }),
  taxiClaims: yup.object().shape({
    TaxiClaims: yup.string().required('Number of claims is required.'),
  }),
  taxiConvictions: yup.object().shape({
    TaxiConvictions: yup
      .string()
      .required('Number of convictions is required.'),
  }),
  taxiDriver: yup.object().shape({
    TaxiDriver: yup.string().required('Answer about the drivers is required.'),
  }),
  aboutYourCompany: yup.object().shape({
    MotorTradingName: yup.string().required('Your trading name is required.'),
    FullTime: yup
      .string()
      .required('Answer about working full-time is required.'),
    TradingFrom: yup.string().required('Trading location is required.'),
    PremisesAddress: yup.object().shape({
      addressLine1: yup.string().required('Address Line 1 is required field.'),
      postTown: yup.string().required('City is required field.'),
      postcode: yup.string().required('Postcode is required field.'),
    }),
  }),
  numberOfVehicles: yup.object().shape({
    NumberOfVehicle: yup.string().required('Number of vehicles is required.'),
  }),
  typeOfVehicle: yup.object().shape({
    TypeOfVehicle: yup.string().required('Type of vehicle is required.'),
  }),
  courierVehicle: yup.object().shape({
    VehicleMake: yup.string().required('Vehicle make is required.'),
    VehicleModel: yup.string().when('TypeOfVehicle', {
      is: (value) => value !== 'Bicycle',
      then: yup.string().required('Vehicle model is required.'),
      otherwise: yup.string(),
    }),
    YearOfRegistration: yup.number().when('TypeOfVehicle', {
      is: (value) => value !== 'Bicycle',
      then: yup
        .number()
        .min(1900, 'Year of registration cannot be before 1900.')
        .max(
          new Date().getFullYear(),
          'Year of registration cannot be after current year.'
        )
        .required('Year of registration is required.'),
      otherwise: yup.number(),
    }),
    EngineType: yup.string().when('TypeOfVehicle', {
      is: (value) => value !== 'Bicycle',
      then: yup.string().required('Engine type and size is required.'),
      otherwise: yup.string(),
    }),
    VehicleWorth: yup.number().required('Vehicle worth is required.'),
  }),
  courierGoods: yup.object().shape({
    TransportGood: yup
      .string()
      .required(
        'You need to select one type of good you transport most often is required.'
      ),
    OtherTypeOfGoods: yup.string().when('TransportGood', {
      is: (value) => value === 'Other',
      then: yup.string().required('The type of goods is required.'),
      otherwise: yup.string(),
    }),
  }),
  workTime: yup.object().shape({
    WorkTime: yup.string().required('Work time is required.'),
  }),
  noFastFoodClaimBonus: yup.object().shape({
    NoFastFoodClaim: yup.string(),
  }),
  deliverCompany: yup.object().shape({
    DeliverCompanies: yup
      .array()
      .required('You need to select at least one delivery company.')
      .min(1, 'You need to select at least one delivery company.'),
  }),
  noCourierClaimBonus: yup
    .object()
    .shape({ NoCourierClaimBonus: yup.string() }),
  noPrivateCarClaimBonus: yup.object().shape({
    NoPrivateCarClaim: yup.string(),
  }),
  courierDrivingLicence: yup.object().shape({
    LicenceYearsHeld: yup
      .string()
      .required('Years of licence hold is required.'),
    BusinessAddress: yup.object().shape({
      addressLine1: yup.string().required('Address Line 1 is required field.'),
      postTown: yup.string().required('City is required field.'),
      postcode: yup.string().required('Postcode is required field.'),
    }),
  }),
  buySellCover: yup.object().shape({
    BuySellCover: yup
      .array()
      .min(1, 'Select at least one trade.')
      .required('Trades selection is required.'),
  }),
  servicesRepairsCover: yup.object().shape({
    ServicesRepairsCover: yup
      .array()
      .min(1, 'Select at least one trade.')
      .required('Trades selection is required.'),
  }),
  otherTradesCover: yup.object().shape({
    OtherTradesCover: yup
      .array()
      .min(1, 'Select at least one trade.')
      .required('Trades selection is required.'),
  }),
  aboutCourierTransport: yup.object().shape({
    CourierTransport: yup
      .string()
      .required('Area of courier service is required.'),
    GoodsInTransitCover: yup.string().required('This question is required.'),
    AverageValueOfGoods: yup.number().required('Value of goods is required.'),
  }),
  anyClaims: yup.object().shape({
    AnyClaimInFiveYears: yup.string(),
  }),
  motoringConviction: yup.object().shape({
    MotoringConviction: yup.string(),
  }),
  motorTradeLicence: yup.object().shape({
    MotorTradeLicence: yup.string(),
  }),
  motorTradePrivateNCD: yup.object().shape({
    MotorTradePrivateNCD: yup.string(),
  }),
  motorTradeNCD: yup.object().shape({
    MotorTradeNCD: yup.string(),
  }),
  motorTradeExperience: yup.object().shape({
    MotorTradeExperience: yup.string(),
  }),
  motorTradeEmployees: yup.object().shape({
    MotorTradeEmployees: yup.string(),
  }),
  motorTradeDriversInsured: yup.object().shape({
    MotorTradeDriversInsured: yup.string(),
  }),
  premisesCover: yup.object().shape({
    PremisesCover: yup
      .string()
      .required('Answer about the premises cover is required.'),
  }),
  motorTradeClaims: yup.object().shape({
    MotorTradeClaims: yup.string(),
  }),
  motorTradeConvictions: yup.object().shape({
    MotorTradeConvictions: yup.string(),
  }),
  aboutYourHorsebox: yup.object().shape({
    HorseboxType: yup.string().required('Type of the horsebox is required.'),
    VehicleMake: yup.string().required('Make of the horsebox is required.'),
    HorseboxModel: yup.string(),
    HorseboxWorth: yup.number().required('Horsebox worth is required.'),
    HorseboxWeight: yup.string().required('Horsebox weight is required.'),
    HorseboxAccomodation: yup
      .string()
      .required('Answer about the living accommodation is required.'),
  }),
  capacity: yup.object().shape({
    Capacity: yup.string(),
  }),
  horseboxDriver: yup.object().shape({
    HorseboxDriver: yup
      .string()
      .required('Answer about the driver is required.'),
  }),
  horseboxExperience: yup.object().shape({
    HorseboxExperience: yup.string(),
  }),
  horseboxClaims: yup.object().shape({
    HorseboxClaims: yup.string(),
  }),
  aboutVehicle: yup.object().shape({
    VehicleType: yup.string().required('Vehicle type is required.'),
    KnowRegistrationNumber: yup
      .string()
      .required('Answer about knowing the registration number is required.'),
  }),
  aboutYourFoodVehicle: yup.object().shape({
    VehicleMake: yup.string().required('Make of the van is required.'),
    VanModel: yup.string().required('Model of the van is required.'),
    YearOfManufactured: yup
      .number()
      .required('Year of manufacture is required.'),
    EngineType: yup.string().required('Engine type is required.'),
    VehicleWorth: yup.number().required('Worth of the vehicle is required.'),
    EquipmentWorth: yup
      .number()
      .required('Worth of the equipment is required.'),
    OvernightLocation: yup
      .string()
      .required('Overnight storing location is required.'),
  }),
  vanDriver: yup.object().shape({
    VanDriver: yup
      .string()
      .required('Answer about the van driver is required.'),
  }),
  trailerUsage: yup.object().shape({
    TrailerUsage: yup
      .array()
      .min(1, 'You need to select at least one use of the trailer.'),
  }),
  aboutYourTrailer: yup.object().shape({
    VehicleMake: yup.string().required('Make of the trailer is required.'),
    TrailerModel: yup.string().required('Model of the trailer is required.'),
    YearOfManufactured: yup
      .number()
      .min(1900, 'Year of manufacture cannot be before 1900.')
      .max(
        new Date().getFullYear(),
        'Year of manufacture cannot be after current year.'
      )
      .required('Year of manufacture is required.'),
    VehicleWorth: yup.number().required('Worth of the vehicle is required.'),
    EquipmentWorth: yup.number(),
    OvernightLocation: yup.string().required('Storing location is required.'),
    PermanentlySited: yup
      .string()
      .required(
        'Answer about the trailer being permanently sited is required.'
      ),
    PublicLiability: yup
      .string()
      .required('Answer about requiring public liability is required.'),
  }),
  aboutYou: yup.object().shape({
    TradingName: yup.string().required('Your name is required.'),
    NumOfVehicles: yup.string().required('Number of vehicles is required.'),
    Postcode: yup.string().required('Postal code is required.'),
  }),
  aboutYourFarmVehicle: yup.object().shape({
    TypeOfVehicle: yup.string().required('Your name is required.'),
    VehicleMake: yup.string().required('Number of vehicles is required.'),
    VehicleModel: yup.string().required('Postal code is required.'),
    YearOfManufactured: yup
      .number()
      .min(1900, 'Year of manufacture cannot be before 1900.')
      .max(
        new Date().getFullYear(),
        'Year of manufacture cannot be after current year.'
      )
      .required('Year of manufacture is required.'),
    EngineType: yup.string().required('Engine size and type are required.'),
    VehicleWorth: yup.number().required('Value of the vehicle is required.'),
  }),
  numberOfFarmVehicles: yup.object().shape({
    '4x4': yup.string(),
    'Commercial Vehicle': yup.string(),
    Digger: yup.string(),
    Harvester: yup.string(),
    JCB: yup.string(),
    'Private Car': yup.string(),
    'Quad/ATV': yup.string(),
    Tractor: yup.string(),
    Truck: yup.string(),
    Van: yup.string(),
    Other: yup.string(),
  }),
  vehicleDriver: yup.object().shape({
    VehicleDriver: yup
      .string()
      .required('Answer about the drivers is required.'),
  }),
  vehicleUse: yup.object().shape({
    OvernightLocation: yup.string().when('NumOfVehicles', {
      is: (value) => value === '1 Vehicle',
      then: yup.string().required('Overnight storing location is required.'),
      otherwise: yup.string(),
    }),
    VehicleUse: yup.string().required('Main vehicle use is required.'),
  }),
  additionalInfo: yup.object().shape({
    TrailerCover: yup
      .string()
      .required('Answer about requiring trailer cover is required.'),
    TrailerWorth: yup.number().required('Value of the trailer is required.'),
    Claims: yup
      .string()
      .required(
        'Answer about the the claims made in the last 5 years is required.'
      ),
    MonitoringConvictions: yup
      .string()
      .required(
        'Answer about the the monitoring convictions made in the last 5 years is required.'
      ),
  }),
  typeOfChauffeurInsurance: yup.object().shape({
    TypeOfInsurance: yup
      .string()
      .required('Answer about the type of cover is required.'),
  }),
  vehiclesToInsure: yup.object().shape({
    VehiclesToInsure: yup
      .string()
      .required('Answer about the number of vehicles to insure is required.'),
  }),
  aboutChauffeurVehicle: yup.object().shape({
    VehicleMake: yup.string().required('Number of vehicles is required.'),
    VehicleModel: yup.string().required('Postal code is required.'),
    VehicleWorth: yup.number().required('Value of the vehicle is required.'),
    YearOfManufactured: yup
      .number()
      .min(1900, 'Year of manufacture cannot be before 1900.')
      .max(
        new Date().getFullYear(),
        'Year of manufacture cannot be after current year.'
      )
      .required('Year of manufacture is required.'),
  }),
  maxChauffeurPassengers: yup.object().shape({
    MaxPassengers: yup
      .string()
      .required('Maximum number of passengers is required.'),
  }),
  stretchVehicle: yup.object().shape({
    StretchVehicle: yup
      .string()
      .required('Answer about the vehicle being a stretch is required.'),
  }),
  additionalChauffeurInfo: yup.object().shape({
    Claims: yup
      .string()
      .required(
        'Answer about the the claims made in the last 5 years is required.'
      ),
    MonitoringConvictions: yup
      .string()
      .required(
        'Answer about the the monitoring convictions made in the last 5 years is required.'
      ),
  }),
};
