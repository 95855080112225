import { lazy } from 'react';
import { validationScheme } from '../../../validation/validationScheme';

const AboutYourCompany = lazy(() =>
  import('../components/motorTrade/AboutYourCompany')
);
const BuySellCover = lazy(() =>
  import('../components/motorTrade/BuySellCover')
);
const ServicesRepairsCover = lazy(() =>
  import('../components/motorTrade/ServicesRepairsCover')
);
const OtherTradesCover = lazy(() =>
  import('../components/motorTrade/OtherTradesCover')
);
const MotorTradeLicence = lazy(() =>
  import('../components/motorTrade/MotorTradeLicence')
);
const MotorTradePrivateNCD = lazy(() =>
  import('../components/motorTrade/MotorTradePrivateNCD')
);
const MotorTradeNCD = lazy(() =>
  import('../components/motorTrade/MotorTradeNCD')
);
const MotorTradeExperience = lazy(() =>
  import('../components/motorTrade/MotorTradeExperience')
);
const MotorTradeEmployees = lazy(() =>
  import('../components/motorTrade/MotorTradeEmployees')
);
const MotorTradeDriversInsured = lazy(() =>
  import('../components/motorTrade/MotorTradeDriversInsured')
);
const PremisesCover = lazy(() =>
  import('../components/motorTrade/PremisesCover')
);
const MotorTradeClaims = lazy(() =>
  import('../components/motorTrade/MotorTradeClaims')
);
const MotorTradeConvictions = lazy(() =>
  import('../components/motorTrade/MotorTradeConvictions')
);
const TypeOfCover = lazy(() => import('../components/TypeOfCover'));
const CoverStartDate = lazy(() => import('../components/CoverStartDate'));
const PersonalInfo = lazy(() => import('../components/PersonalInfo'));

export const motorTradeStepper = () => [
  {
    name: 'About Your Company',
    component: AboutYourCompany,
    validation: validationScheme.aboutYourCompany,
  },
  {
    name: 'Buy & Sell Trades',
    component: BuySellCover,
    validation: validationScheme.buySellCover,
  },
  {
    name: 'Services & Repairs Trades',
    component: ServicesRepairsCover,
    validation: validationScheme.servicesRepairsCover,
  },
  {
    name: 'Other Trades',
    component: OtherTradesCover,
    validation: validationScheme.otherTradesCover,
  },
  {
    name: 'Full UK Licence',
    component: MotorTradeLicence,
    validation: validationScheme.motorTradeLicence,
  },
  {
    name: 'Private Car NCD',
    component: MotorTradePrivateNCD,
    validation: validationScheme.motorTradePrivateNCD,
  },
  {
    name: 'Motor Trade NCD',
    component: MotorTradeNCD,
    validation: validationScheme.motorTradeNCD,
  },
  {
    name: 'Motor Trade Experience',
    component: MotorTradeExperience,
    validation: validationScheme.motorTradeExperience,
  },
  {
    name: 'Employees',
    component: MotorTradeEmployees,
    validation: validationScheme.motorTradeEmployees,
  },
  {
    name: 'Drivers Insured',
    component: MotorTradeDriversInsured,
    validation: validationScheme.motorTradeDriversInsured,
  },
  {
    name: 'Type of Cover',
    component: TypeOfCover,
    validation: validationScheme.typeOfCover,
  },
  {
    name: 'Premises Cover',
    component: PremisesCover,
    validation: validationScheme.premisesCover,
  },
  {
    name: 'Claims',
    component: MotorTradeClaims,
    validation: validationScheme.motorTradeClaims,
  },
  {
    name: 'Motoring Convictions',
    component: MotorTradeConvictions,
    validation: validationScheme.motorTradeConvictions,
  },
  {
    name: 'Personal Info',
    component: PersonalInfo,
    validation: validationScheme.personalInfo,
  },
  {
    name: 'Cover Start Date',
    component: CoverStartDate,
    validation: validationScheme.coverStartDate,
    action: 'savePolicy',
  },
];
