import React from 'react';
import { css, cx } from '@emotion/css';
import { useFormikField } from '../../utils/use-formik-field';
import { InputDescription } from './InputDescription';
import { ErrorMessage } from './ErrorMessage';
import { Tooltip } from './Tooltip';

const inputContainerStyle = css`
  margin: 0.3125rem 0 1rem 0;
`;

const errorBorderStyle = css`
  border: 2px solid var(--error);
  margin-bottom: 0.75rem;
`;

const disabledStyle = css`
  background-color: var(--white) !important;
  border: none !important;
  color: var(--silver) !important;
  position: absolute;
  bottom: -1px;
  top: 0;
  height: calc(1.5em + 0.75rem + 6px);
  border-radius: 0;
`;
const disabledBorderStyle = css`
  border: 2px solid var(--gray) !important;
`;

const customInputStyle = css`
  width: 100%;
  height: 100%;
  padding: 0.625rem 1rem;
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 1.375rem;
  border: none !important;
  border-radius: 0;
  background-color: transparent;
  position: absolute;
  outline: none !important;
  color: var(--dark);
  left: 0;
  top: 0;
  &:focus {
    + label {
      background-color: var(--white);
      width: auto;
      color: var(--primaryBrand);
      transition: all 0.25s ease-out;
      padding: 0 0.375rem;
      transform: translate(-0.5rem, -1.9rem);
      font-size: 0.75rem;
      z-index: 2;
    }
    ~ .borderDiv {
      outline-color: var(--primaryBrand);
    }
  }
  &:focus {
    outline: none;
    box-shadow: none !important;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const groupButtonStyle = css`
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--primaryBrand);
  border: none;
  margin-left: 1rem;
  height: 2.875rem;
  padding: 0 1rem;
  background: transparent;
  display: inline;
  &:focus {
    outline: none;
  }
`;

const addOnLabelStyle = css`
  border-bottom: 2px solid var(--primaryBrand);
`;

const stretchedContainerStyle = css`
  display: flex;
  align-items: stretch;
  position: relative;
`;

const labelStyle = css`
  //width: 90%;
  position: absolute;
  transition: transform 0.25s ease-out;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--white);
  line-height: 1rem;
  font-size: 1rem;
  color: var(--darkGray);
  pointer-events: none;
  padding: 0.5rem 0;
`;

const customInputContainer = css`
  position: relative;
  border: 2px solid var(--gray);
  flex: 1;
  height: 2.875rem;
`;

const hasValueState = css`
  label {
    color: var(--gray);
    background-color: var(--white);
    width: auto;
    transition: all 0.25s ease-out;
    padding: 0 0.375rem;
    transform: translate(-0.5rem, -1.9rem);
    font-size: 0.75rem;
    z-index: 2;
  }
`;
const groupIconButtonStyle = css`
  position: absolute !important;
  right: 0.75rem;
  background-color: transparent;
  border: none;
  height: 100%;
  padding: 0;
`;

const groupIconTooltipButtonStyle = css`
  position: absolute !important;
  right: 0.75rem;
  background-color: transparent;
  border: none;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const borderStyle = css`
  position: absolute;
  width: 100%;
  height: 100%;
  outline: 2px solid transparent;
  pointer-events: none;
`;
const requiredAsteriskStyle = css`
  :after {
    content: '*';
    color: var(--error);
  }
`;
const beforeIconStyle = css`
  height: 1.5rem;
  margin-left: 1rem;
  position: absolute;
  top: 0.5rem;
`;
const beforeIconPositionFixedStyle = css`
  width: calc(100% - 2.5rem);
  left: 2.5rem;
  &:focus {
    + label {
      transform: translate(-2.5rem, -1.9rem); !important;
    }
  }
`;
const beforePositionLabelFixedStyle = css`
  left: 3.125rem;
`;

const differentValuePositionStyle = css`
  label {
    transform: translate(-2.5rem, -1.9rem) !important;
  }
`;

const fullWidthStyle = css`
  width: 100%;
`;

const sfMarginStyle = css`
  margin: 0.625rem 0;
`;

const sfInputStyle = css`
  background-color: #fff;
  border-radius: 2px;
  color: #333;
  font-size: 1rem;
  border: 1px solid #ccc;
  padding: 0.375rem 0.625rem;
  height: 2.375rem;
  width: 100%;
  line-height: 1.3;
  &:focus {
    outline: none;
  }
`;

const sfHasValueStyle = css`
  label {
    display: none;
  }
`;

const sfLabelStyle = css`
  color: #7b8794;
  left: 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  pointer-events: none;
`;

const sfInputContainer = css`
  position: relative;
  flex: 1;
`;

export const InputComponent = (props) => {
  const {
    name,
    id,
    onChange,
    description,
    value,
    type,
    error,
    placeholder,
    onAddOnClick,
    addOnLabel,
    onBlur,
    touched,
    disabled,
    min,
    onChangeSideEffect,
    required,
    onFocus,
    focused,
    addOnIcon,
    skipError,
    beforeIcon,
    tooltip,
    fullwidth,
    salesForce,
  } = props;

  const handleChange = (e) => {
    onChangeSideEffect(e.target.value);
    onChange(e);
  };

  const handleNumberChange = (e) => {
    (/^-?\d+$/.test(e.target.value) || e.target.value === '') && onChange(e);
  };

  return (
    <div className={fullwidth && fullWidthStyle}>
      <div className={cx(inputContainerStyle, salesForce && sfMarginStyle)}>
        <div className={stretchedContainerStyle}>
          <div
            className={cx(
              salesForce ? sfInputContainer : customInputContainer,
              value && (salesForce ? sfHasValueStyle : hasValueState),
              value && beforeIcon && differentValuePositionStyle,
              error && touched && !focused && !salesForce && errorBorderStyle,
              disabled && disabledBorderStyle
            )}
          >
            {beforeIcon && (
              <img src={beforeIcon} alt="" className={beforeIconStyle} />
            )}
            <input
              name={name}
              id={id}
              value={value}
              onChange={
                onChangeSideEffect
                  ? handleChange
                  : type === 'number'
                  ? handleNumberChange
                  : onChange
              }
              onBlur={onBlur}
              onFocus={onFocus}
              disabled={disabled}
              min={min}
              required={required}
              className={cx(
                disabled
                  ? disabledStyle
                  : salesForce
                  ? sfInputStyle
                  : customInputStyle,
                beforeIcon ? beforeIconPositionFixedStyle : ''
              )}
            />
            <label
              className={cx(
                labelStyle,
                salesForce && sfLabelStyle,
                required ? requiredAsteriskStyle : '',
                beforeIcon ? beforePositionLabelFixedStyle : ''
              )}
              htmlFor={id}
            >
              {placeholder}
            </label>
            <div className={cx(borderStyle, 'borderDiv')} />
            {((onAddOnClick && addOnIcon) || tooltip) && (
              <div>
                {addOnIcon && onAddOnClick && (
                  <button
                    type="button"
                    onClick={(e) => onAddOnClick(e, !value)}
                    disabled={disabled}
                    className={groupIconButtonStyle}
                  >
                    <img src={addOnIcon} alt="extra" />
                  </button>
                )}
                {tooltip && (
                  <Tooltip id={id} customStyle={groupIconTooltipButtonStyle}>
                    <p>{tooltip}</p>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
          {onAddOnClick && addOnLabel && (
            <div>
              <button
                type="button"
                onClick={onAddOnClick}
                disabled={disabled}
                className={groupButtonStyle}
              >
                <div className={addOnLabelStyle}>{addOnLabel}</div>
              </button>
            </div>
          )}
        </div>
        {error && touched && !focused && !skipError && (
          <ErrorMessage errorMessage={error} showIcon salesForce={salesForce} />
        )}
      </div>
      <InputDescription text={description} />
    </div>
  );
};

export const InputField = (props) => {
  const {
    label,
    description,
    name,
    id,
    type,
    placeholder,
    onAddOnClick,
    addOnLabel,
    disabled,
    min,
    onChangeSideEffect,
    required,
    fixedValue,
    addOnIcon,
    skipError,
    setFocused,
    beforeIcon,
    tooltip,
    fullwidth,
    salesForce,
  } = props;

  const {
    value,
    onBlur,
    setValue,
    isInErrorState,
    errorMessage,
    touched,
    onFocus,
    focused,
  } = useFormikField(name);

  // on address info field focus is not set properly because of two components with same name, so we need to put it manually
  if (setFocused) {
    setFocused(focused);
  }

  return (
    <InputComponent
      name={name}
      id={id}
      label={label}
      type={type}
      description={description}
      value={fixedValue || value || ''}
      invalid={isInErrorState}
      error={errorMessage}
      onChange={setValue}
      placeholder={placeholder}
      onAddOnClick={onAddOnClick}
      addOnLabel={addOnLabel}
      onBlur={onBlur}
      onFocus={onFocus}
      focused={focused}
      touched={touched}
      disabled={disabled}
      min={min}
      onChangeSideEffect={onChangeSideEffect}
      required={required}
      addOnIcon={addOnIcon}
      skipError={skipError}
      beforeIcon={beforeIcon}
      tooltip={tooltip}
      fullwidth={fullwidth}
      salesForce={salesForce}
    />
  );
};
