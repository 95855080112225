import { lazy } from 'react';
import { validationScheme } from '../../../validation/validationScheme';

const AboutYou = lazy(() =>
  import('../components/agriculturalVehicle/AboutYou')
);
const AboutYourFarmVehicle = lazy(() =>
  import('../components/agriculturalVehicle/AboutYourFarmVehicle')
);
const NumberOfVehicles = lazy(() =>
  import('../components/agriculturalVehicle/NumberOfVehicles')
);
const TypeOfCover = lazy(() => import('../components/TypeOfCover'));
const VehicleDriver = lazy(() =>
  import('../components/agriculturalVehicle/VehicleDriver')
);
const NoClaimsBonus = lazy(() =>
  import('../components/agriculturalVehicle/NoClaimsBonus')
);
const VehicleUsage = lazy(() =>
  import('../components/agriculturalVehicle/VehicleUsage')
);
const AdditionalInfo = lazy(() =>
  import('../components/agriculturalVehicle/AdditionalInfo')
);
const PersonalInfo = lazy(() => import('../components/PersonalInfo'));
const CoverStartDate = lazy(() => import('../components/CoverStartDate'));
const RegistrationNumber = lazy(() =>
  import('../components/RegistrationNumber')
);

export const agriculturalVehicleStepper = (extraCheck) => [
  {
    name: 'About You',
    component: AboutYou,
    validation: validationScheme.aboutYou,
  },
  ...(extraCheck.numOfVehicles === '1 Vehicle'
    ? [
        {
          name: 'Vehicle Registration',
          component: RegistrationNumber,
          validation: validationScheme.registrationNumber,
        },
        {
          name: 'About Your Vehicle',
          component: AboutYourFarmVehicle,
          validation: validationScheme.aboutYourFarmVehicle,
        },
      ]
    : [
        {
          name: 'Number of Vehicles',
          component: NumberOfVehicles,
          validation: validationScheme.numberOfFarmVehicles,
        },
      ]),
  {
    name: 'Type of Cover',
    component: TypeOfCover,
    validation: validationScheme.typeOfCover,
  },
  {
    name: 'Vehicle Drivers',
    component: VehicleDriver,
    validation: validationScheme.vehicleDriver,
  },
  {
    name: 'No Claims Bonus',
    component: NoClaimsBonus,
    validation: validationScheme.noClaimBonus,
  },
  {
    name: 'Vehicle Use',
    component: VehicleUsage,
    validation: validationScheme.vehicleUse,
  },
  {
    name: 'Additional Info',
    component: AdditionalInfo,
    validation: validationScheme.additionalInfo,
  },
  {
    name: 'Personal Info',
    component: PersonalInfo,
    validation: validationScheme.personalInfo,
  },
  {
    name: 'Cover Start Date',
    component: CoverStartDate,
    validation: validationScheme.coverStartDate,
    action: 'savePolicy',
  },
];
