import { lazy } from 'react';
import { validationScheme } from '../../../validation/validationScheme';

const TypeOfCover = lazy(() => import('../components/foodVehicle/TypeOfCover'));
const AboutVehicle = lazy(() =>
  import('../components/foodVehicle/AboutVehicle')
);
const VanDriver = lazy(() => import('../components/foodVehicle/VanDriver'));
const TrailerUsage = lazy(() =>
  import('../components/foodVehicle/TrailerUsage')
);
const AboutYourTrailer = lazy(() =>
  import('../components/foodVehicle/AboutYourTrailer')
);
const AboutYouVan = lazy(() =>
  import('../components/foodVehicle/AboutYourVehicle')
);
const PersonalInfo = lazy(() => import('../components/PersonalInfo'));
const CoverStartDate = lazy(() => import('../components/CoverStartDate'));

export const foodVehicleStepper = (extraCheck) => [
  {
    name: 'Type of Cover',
    component: TypeOfCover,
    validation: validationScheme.typeOfCover,
  },
  ...(extraCheck.typeOfCover === 'Catering Van Insurance'
    ? [
        {
          name: 'About Vehicle',
          component: AboutVehicle,
          validation: validationScheme.aboutVehicle,
        },
        {
          name: 'About Your Van',
          component: AboutYouVan,
          validation: validationScheme.aboutYourFoodVehicle,
        },
        {
          name: 'Van Driver',
          component: VanDriver,
          validation: validationScheme.vanDriver,
        },
      ]
    : [
        {
          name: 'Trailer Usage',
          component: TrailerUsage,
          validation: validationScheme.trailerUsage,
        },
        {
          name: 'About Your Trailer',
          component: AboutYourTrailer,
          validation: validationScheme.aboutYourTrailer,
        },
      ]),
  {
    name: 'Personal Info',
    component: PersonalInfo,
    validation: validationScheme.personalInfo,
  },
  {
    name: 'Cover Start Date',
    component: CoverStartDate,
    validation: validationScheme.coverStartDate,
    action: 'savePolicy',
  },
];
