import { lazy } from 'react';
import { validationScheme } from '../../../validation/validationScheme';

const AboutMinibus = lazy(() => import('../components/minibus/AboutMinibus'));
const AboutMinibusClaim = lazy(() =>
  import('../components/minibus/AboutMinibusClaim')
);
const CoverPeriod = lazy(() => import('../components/minibus/CoverPeriod'));
const MakeOfTheMinibus = lazy(() =>
  import('../components/minibus/MakeOfTheMinibus')
);
const MinibusDriver = lazy(() => import('../components/minibus/MinibusDriver'));
const MinibusNoClaims = lazy(() =>
  import('../components/minibus/MinibusNoClaims')
);
const MinibusNumberOfPassengers = lazy(() =>
  import('../components/minibus/MinibusNumberOfPassengers')
);
const MinibusUse = lazy(() => import('../components/minibus/MinibusUse'));
const PrivateCarNoClaim = lazy(() =>
  import('../components/minibus/PrivateCarNoClaim')
);
const TypeOfCover = lazy(() => import('../components/TypeOfCover'));
const PersonalInfo = lazy(() => import('../components/PersonalInfo'));
const CoverStartDate = lazy(() => import('../components/CoverStartDate'));
const RegistrationNumber = lazy(() =>
  import('../components/RegistrationNumber')
);

export const minibusStepper = () => [
  {
    name: 'Minibus Registration',
    component: RegistrationNumber,
    validation: validationScheme.registrationNumber,
  },
  {
    name: 'Make of the Minibus',
    component: MakeOfTheMinibus,
    validation: validationScheme.minibusMake,
  },
  {
    name: 'About your Minibus',
    component: AboutMinibus,
    validation: validationScheme.aboutMinibus,
  },
  {
    name: 'Main minibus use',
    component: MinibusUse,
    validation: validationScheme.mainMinibusUse,
  },
  {
    name: 'Number of passengers',
    component: MinibusNumberOfPassengers,
    validation: validationScheme.minibusNumberOfPassengers,
  },
  {
    name: 'Vehicle driver',
    component: MinibusDriver,
    validation: validationScheme.minibusDrivingLicence,
  },
  {
    name: 'Type of Cover',
    component: TypeOfCover,
    validation: validationScheme.typeOfCover,
  },
  {
    name: 'Cover Period',
    component: CoverPeriod,
    validation: validationScheme.coverPeriod,
  },
  {
    name: 'Minibus claim',
    component: MinibusNoClaims,
    validation: validationScheme.minibusNoClaimBonus,
  },
  {
    name: 'Private car claim',
    component: PrivateCarNoClaim,
    validation: validationScheme.privateCarNoClaimBonus,
  },
  {
    name: 'About claims',
    component: AboutMinibusClaim,
    validation: validationScheme.aboutMinibusClaim,
  },
  {
    name: 'Personal Info',
    component: PersonalInfo,
    validation: validationScheme.personalInfo,
  },
  {
    name: 'Cover Start Date',
    component: CoverStartDate,
    validation: validationScheme.coverStartDate,
    action: 'savePolicy',
  },
];
