import { lazy } from 'react';
import { validationScheme } from '../../../validation/validationScheme';

const TypeOfCover = lazy(() => import('../components/TypeOfCover'));
const AboutYourTaxi = lazy(() => import('../components/taxi/AboutYourTaxi'));
const TypeOfTaxi = lazy(() => import('../components/taxi/TypeOfTaxi'));
const MaxPassengers = lazy(() => import('../components/taxi/MaxPassengers'));
const TaxiLicence = lazy(() => import('../components/taxi/TaxiLicence'));
const TaxiBadge = lazy(() => import('../components/taxi/TaxiBadge'));
const TaxiNCD = lazy(() => import('../components/taxi/TaxiNCD'));
const PrivateCarNCD = lazy(() => import('../components/taxi/PrivateCarNCD'));
const TaxiClaims = lazy(() => import('../components/taxi/TaxiClaims'));
const TaxiConvictions = lazy(() =>
  import('../components/taxi/TaxiConvictions')
);
const TaxiDriver = lazy(() => import('../components/taxi/TaxiDriver'));
const CoverStartDate = lazy(() => import('../components/CoverStartDate'));
const PersonalInfo = lazy(() => import('../components/PersonalInfo'));
const RegistrationNumber = lazy(() =>
  import('../components/RegistrationNumber')
);

export const taxiStepper = () => [
  {
    name: 'Taxi Registration',
    component: RegistrationNumber,
    validation: validationScheme.registrationNumber,
  },
  {
    name: 'About Your Taxi',
    component: AboutYourTaxi,
    validation: validationScheme.aboutYourTaxi,
  },
  {
    name: 'Type of Taxi',
    component: TypeOfTaxi,
    validation: validationScheme.typeOfTaxi,
  },
  {
    name: 'Maximum Passengers',
    component: MaxPassengers,
    validation: validationScheme.maxPassengers,
  },
  {
    name: 'Taxi Licence',
    component: TaxiLicence,
    validation: validationScheme.taxiLicence,
  },
  {
    name: 'Taxi Badge',
    component: TaxiBadge,
    validation: validationScheme.taxiBadge,
  },
  {
    name: 'Taxi NCD',
    component: TaxiNCD,
    validation: validationScheme.taxiNCD,
  },
  {
    name: 'Private Car NCD',
    component: PrivateCarNCD,
    validation: validationScheme.privateCarNCD,
  },
  {
    name: 'Taxi Claims',
    component: TaxiClaims,
    validation: validationScheme.taxiClaims,
  },
  {
    name: 'Taxi Convictions',
    component: TaxiConvictions,
    validation: validationScheme.taxiConvictions,
  },
  {
    name: 'Type of Cover',
    component: TypeOfCover,
    validation: validationScheme.typeOfCover,
  },
  {
    name: 'Taxi Driver',
    component: TaxiDriver,
    validation: validationScheme.taxiDriver,
  },
  {
    name: 'Personal Info',
    component: PersonalInfo,
    validation: validationScheme.personalInfo,
  },
  {
    name: 'Cover Start Date',
    component: CoverStartDate,
    validation: validationScheme.coverStartDate,
    action: 'savePolicy',
  },
];
