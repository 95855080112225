import React, { useRef } from 'react';
import { Formik } from 'formik';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react';
import { css } from '@emotion/css';
import { initialValues } from '../../validation/initialValues';
import { stepStore } from '../../store/step-store';
import { useWindowSize } from '../../utils/useWindowSize';
import { Header } from '../components/Header';
import { LeftSidebar } from '../components/LeftSidebar';
import { RightSidebar } from '../components/RightSidebar';
import { PolicyDisclaimerText } from '../components/PolicyDisclaimerText';
import { Footer } from '../components/Footer';
import { stateStore } from '../../store/state-store';

const bodyStyle = css`
  min-height: calc(100vh - 10px);
  display: grid;
  grid-template-columns: Max(25vw, 14.5rem) 1fr Max(25vw, 14.5rem);
  grid-template-areas:
    'header header header'
    'aside main info'
    'footer footer footer';
  background-color: var(--white);
  @media (max-width: 768px) {
    grid-template-areas:
      'header'
      'aside'
      'main'
      'info'
      'footer';
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
`;

const contentContainer = css`
  grid-area: main;
  display: flex;
  position: relative;
  flex-direction: column;
  @media (max-width: 768px) {
    min-height: calc(100vh - 6rem);
  }
`;

const getValidationScheme = (step) => {
  return stateStore.stepper[step]?.validation;
};

export const FormikWrapper = observer(() => {
  const formikRef = useRef();
  const windowSize = useWindowSize();
  const { step } = stepStore;

  const handleFormikSubmit = (values, formikBag) => {
    stepStore.nextStep();
    formikBag.setTouched({}, false);
    window.scrollTo(0, 0);
  };
  return (
    <div className={bodyStyle}>
      <Header />
      <main className={contentContainer}>
        <Formik
          initialValues={initialValues({})}
          validationSchema={() => getValidationScheme(step)}
          onSubmit={handleFormikSubmit}
        >
          {(formik) => {
            formikRef.current = formik;
            return <Outlet />;
          }}
        </Formik>
      </main>
      <LeftSidebar />
      <RightSidebar>
        {step === 1 && windowSize.width > 768 && <PolicyDisclaimerText />}
      </RightSidebar>
      <Footer />
    </div>
  );
});
