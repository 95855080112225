export const initialValues = (values) => ({
  /* TYPE OF INSURANCE */
  Policyholder: {
    Name: `${values.firstName || ''} ${values.lastName || ''}`,
    Title: '',
    DateOfBirth: '',
    Phone: `${values.phone || ''}`,
    Email: `${values.email || ''}`,
    Address: {
      addressLine1: '',
      addressLine2: '',
      postcode: '',
      postTown: '',
    },
    BornInTheUK: '',
    RelationshipStatus: '',
    NumberOfChildren: '',
    VehicleAtThisAddress: '',
    HomeOwner: '',
    Profession: '',
    Industry: '',
    AnotherJob: '',
    OtherProfession: '',
    OtherIndustry: '',
    EmploymentStatus: '',
  },
  InsuranceType: '',
  TradingName: '',
  BusinessAddress: {
    addressLine1: '',
    addressLine2: '',
    postcode: '',
    postTown: '',
  },
  FleetType: '',
  TypeOfCover: '',
  TradeOccupation: '',
  YearsTrading: 0,
  VehicleDriver: '',
  AreFleetRated: '',
  NumberOfCars: null,
  NumberOfVans: null,
  NumberOfMotorbikes: null,
  NumberOfOtherVehicles: null,
  NumberOfPickUps: null,
  NumberOfLorriesTrucks: null,
  NumberOfTaxis: null,
  NumberOfMinibuses: null,

  /* VAN REGISTRATION NUMBER */
  registrationNumber: '',
  /* MAKE OF THE VAN */
  /* VAN MODEL */
  VanModelName: '',
  /* VEHICLE USAGE */
  OwnerAndKeeper: '',
  Owner: '',
  RegisteredKeeper: '',
  OvernightLocation: '',
  VehicleUse: '',
  AnnualMileage: undefined,
  /* ABOUT YOUR VEHICLE */
  YearOfRegistration: undefined,
  EngineType: '',
  // ConfirmVehicle: '',
  NumberOfSeats: 1,
  VehicleWorth: undefined,
  Modified: '',
  BodyType: '',
  AlreadyBought: '',
  TimeOfPurchase: undefined,
  /* VAN PERSONAL INFO */
  VanOwnerTitle: {
    code: '',
    value: '',
  },
  VanOwnerName: '',
  VanOwnerDateOfBirth: undefined,
  VanOwnerAddress: {
    addressLine1: '',
    addressLine2: '',
    postcode: '',
    postTown: '',
  },

  VanOwnerEmail: '',
  VanOwnerPhone: '',

  /* LICENCE AND DRIVING HISTORY */
  DrivingLicence: '',
  YearsDriving: '',
  LicenceNumber: '',
  AdvancedMotorists: '',
  MedicalConditions: '',
  AccidentsClaimsLosses: '',
  DrivingRelatedConvictions: '',
  NonMotoringConvictions: '',
  /* ADDITIONAL DRIVERS */
  AdditionalDrivers: '',
  /* VOLUNTARY EXCESS */
  VoluntaryExcess: '',
  /* NO CLAIMS DISCOUNT */
  VanNCD: '',
  /* FINAL DETAILS */
  OtherVehicles: '',
  NumberOfVehicles: 0,
  MonthlyPayment: '',
  InsuranceDeclined: '',

  // truck
  TruckType: '',
  VehicleWeight: '',
  RegistrationNumber: '',
  VehicleModel: '',
  YearOfManufactured: undefined,
  DrivingLicenceType: '',
  LicenceYearsHeld: '',
  YearsOfNoClaim: '',
  MainUseOfVehicle: '',
  OperateType: '',
  OperatePostcode: {
    addressLine1: '',
    addressLine2: '',
    postcode: '',
    postTown: '',
  },
  AnyHazardousGoods: '',

  // minibus
  CoverPeriod: '',
  MinibusNoClaimBonus: '',
  PrivateCarNoClaimBonus: '',
  ClaimLastFiveYears: '',
  MotoringConviction: '',
  DrivingLicenceYearsOld: '',
  NumberOfPassengers: '',
  EngineSizeAndType: '',
  MainMinibusUse: '',
  // bus
  NumberOfBuses: '',
  BusUse: [],
  MaxNumberOfPassengers: '',
  NoClaimBonus: '',
  /* TAXI INSURANCE */
  /* ABOUT YOUR VEHICLE */
  TaxiModel: '',
  TaxiRegistrationYear: undefined,
  TaxiEngineType: '',
  TaxiWorth: undefined,
  /* TAXI USAGE */
  TypeOfTaxi: '',
  TaxiUsage: '',
  UberDriver: '',
  /* MAX PASSENGERS */
  MaxNumber: '',
  /* TAXI LICENCE */
  LicenceTime: '',
  /* TAXI BADGE */
  TaxiBadge: '',
  TaxiNCD: '',
  TaxiCarNCD: '',
  PrivateCarNCD: '',
  TaxiClaims: '',
  TaxiConvictions: '',
  TaxiDriver: '',
  /* MOTOR TRADE */
  MotorTradingName: '',
  FullTime: '',
  TradingFrom: '',
  PremisesAddress: {
    addressLine1: '',
    addressLine2: '',
    postcode: '',
    postTown: '',
  },
  BuySellCover: [],
  ServicesRepairsCover: [],
  OtherTradesCover: [],
  MotorTradeLicence: '',
  MotorTradePrivateNCD: '',
  MotorTradeNCD: '',
  MotorTradeExperience: '',
  MotorTradeEmployees: '',
  MotorTradeDriversInsured: '',
  PremisesCover: '',
  MotorTradeClaims: '',
  // courier
  NumberOfVehicle: '',
  TypeOfVehicle: '',
  VehicleMake: '',
  TransportGood: '',
  OtherTypeOfGoods: '',
  WorkTime: '',
  NoFastFoodClaim: '',
  DeliverCompanies: [],
  NoCourierClaimBonus: '',
  NoPrivateCarClaim: '',
  CourierTransport: '',
  GoodsInTransitCover: '',
  AverageValueOfGoods: '',
  AnyClaimInFiveYears: '',
  /* HORSEBOX */
  HorseboxType: '',
  HorseboxModel: '',
  HorseboxRegistration: '',
  HorseboxWorth: undefined,
  HorseboxWeight: '',
  HorseboxAccomodation: '',
  Capacity: '',
  HorseboxDriver: '',
  HorseboxExperience: '',
  HorseboxClaims: '',
  /* ICE CREAM, CATERING, FOOD TRUCK */
  VehicleType: '',
  VanModel: '',
  KnowRegistrationNumber: '',
  EquipmentWorth: '',
  TrailerModel: '',
  PermanentlySited: '',
  PublicLiability: '',
  TrailerUsage: [],
  /* AGRICULTURAL & FARM VEHICLES */
  NumOfVehicles: undefined,
  Postcode: '',
  '4x4': '0',
  'Commercial Vehicle': '0',
  Digger: '0',
  Harvester: '0',
  JCB: '0',
  'Private Car': '0',
  'Quad/ATV': '0',
  Tractor: '0',
  Truck: '0',
  Van: '0',
  Other: '0',
  EngineSize: '',
  TrailerCover: '',
  TrailerWorth: '',
  Claims: '',
  MonitoringConvictions: '',
  /* EXECUTIVE & CHAUFFEUR */
  TypeOfInsurance: '',
  VehiclesToInsure: '',
  StretchVehicle: '',
  MaxPassengers: '',

  // other
  EffectivePeriodStartDate: new Date(),
});
