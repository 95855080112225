import { lazy } from 'react';
import { validationScheme } from '../../../validation/validationScheme';

const MakeOfTheVan = lazy(() => import('../components/van/MakeOfTheVan'));
const VanModel = lazy(() => import('../components/van/VanModel'));
const VehicleUsage = lazy(() => import('../components/van/VehicleUsage'));
const AboutYourVehicle = lazy(() =>
  import('../components/van/AboutYourVehicle')
);
const VanPersonalInfo = lazy(() => import('../components/van/VanPersonalInfo'));
const LicenceDrivingHistory = lazy(() =>
  import('../components/van/LicenceDrivingHistory')
);
const AdditionalDrivers = lazy(() =>
  import('../components/van/AdditionalDrivers')
);
const VoluntaryExcess = lazy(() => import('../components/van/VoluntaryExcess'));
const NoClaimsDiscount = lazy(() =>
  import('../components/van/NoClaimsDiscount')
);
const FinalDetails = lazy(() => import('../components/van/FinalDetails'));
const TypeOfCover = lazy(() => import('../components/TypeOfCover'));
const VanCoverStartDate = lazy(() =>
  import('../components/van/VanCoverStartDate')
);
const RegistrationNumber = lazy(() =>
  import('../components/RegistrationNumber')
);

export const vanStepper = () => [
  {
    name: 'Van Registration',
    component: RegistrationNumber,
    validation: validationScheme.registrationNumber,
  },
  {
    name: 'Make Of The Van',
    component: MakeOfTheVan,
    validation: validationScheme.makeOfTheVan,
  },
  {
    name: 'Van Model',
    component: VanModel,
    validation: validationScheme.vanModel,
  },
  {
    name: 'About Your Vehicle',
    component: AboutYourVehicle,
    validation: validationScheme.aboutYourVehicle,
  },
  {
    name: 'Vehicle Usage',
    component: VehicleUsage,
    validation: validationScheme.vehicleUsage,
  },
  {
    name: 'Van Personal & Contact Info',
    component: VanPersonalInfo,
    validation: validationScheme.vanPersonalInfo,
  },
  {
    name: 'Licence & Driving History',
    component: LicenceDrivingHistory,
    validation: validationScheme.licenceDrivingHistory,
  },
  {
    name: 'Additional Drivers',
    component: AdditionalDrivers,
    validation: validationScheme.additionalDrivers,
  },
  {
    name: 'Type of Cover',
    component: TypeOfCover,
    validation: validationScheme.typeOfCover,
  },
  {
    name: 'Voluntary Excess',
    component: VoluntaryExcess,
    validation: validationScheme.voluntaryExcess,
  },
  {
    name: 'No Claims Discount',
    component: NoClaimsDiscount,
    validation: validationScheme.vanNCD,
  },
  {
    name: 'Final Details',
    component: FinalDetails,
    validation: validationScheme.finalDetails,
  },
  {
    name: 'Cover Start Date',
    component: VanCoverStartDate,
    validation: validationScheme.coverStartDate,
    action: 'savePolicy',
  },
];
