import { lazy } from 'react';
import { validationScheme } from '../../../validation/validationScheme';

const NumberOfBuses = lazy(() =>
  import('../components/busCoach/NumberOfBuses')
);
const AboutBus = lazy(() => import('../components/busCoach/AboutBus'));
const BusUse = lazy(() => import('../components/busCoach/BusUse'));
const BusDriver = lazy(() => import('../components/busCoach/BusDriver'));
const NoClaimBonus = lazy(() => import('../components/busCoach/NoClaimBonus'));
const PersonalInfo = lazy(() => import('../components/PersonalInfo'));
const CoverStartDate = lazy(() => import('../components/CoverStartDate'));

export const busStepper = () => [
  {
    name: 'Number of buses/coaches',
    component: NumberOfBuses,
    validation: validationScheme.numberOfBuses,
  },
  {
    name: 'Bus/coach use',
    component: BusUse,
    validation: validationScheme.mainBusUse,
  },
  {
    name: 'Driver Type',
    component: BusDriver,
    validation: validationScheme.fleetDriverType,
  },
  {
    name: 'About Buses/coaches',
    component: AboutBus,
    validation: validationScheme.aboutBus,
  },
  {
    name: 'No Claim',
    component: NoClaimBonus,
    validation: validationScheme.noClaimBonus,
  },
  {
    name: 'Personal Info',
    component: PersonalInfo,
    validation: validationScheme.personalInfo,
  },
  {
    name: 'Cover Start Date',
    component: CoverStartDate,
    validation: validationScheme.coverStartDate,
    action: 'savePolicy',
  },
];
