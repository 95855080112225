import React, { lazy, Suspense } from 'react';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';
import { css } from '@emotion/css';
import { stepStore } from '../../store/step-store';
import { stateStore } from '../../store/state-store';
import { Loader } from '../components/Loader';
import { validationScheme } from '../../validation/validationScheme';
import {
  BUS_COACH,
  MINIBUS,
  MOTOR_FLEET,
  TRUCK_HGV,
  VAN,
  TAXI,
  MOTOR_TRADE,
  COURIER,
  HORSEBOX,
  FOOD_VEHICLE,
  AGRICULTURAL_FARM,
  EXECUTIVE_CHAUFFEUR,
} from '../../constants/constants';
import { minibusStepper } from './stepper/minibusStepper';
import { busStepper } from './stepper/busStepper';
import { truckStepper } from './stepper/truckStepper';
import { motorFleetStepper } from './stepper/motorFleetStepper';
import { vanStepper } from './stepper/vanStepper';
import { taxiStepper } from './stepper/taxiStepper';
import { motorTradeStepper } from './stepper/motorTradeStepper';
import { courierStepper } from './stepper/courierStepper';
import { horseboxStepper } from './stepper/horseboxStepper';
import { foodVehicleStepper } from './stepper/foodVehicleStepper';
import { agriculturalVehicleStepper } from './stepper/agriculturalVehicleStepper';
import { chauffeurStepper } from './stepper/chauffeurStepper';

const PolicyDisclaimer = lazy(() => import('./components/PolicyDisclaimer'));
const TypeOfInsurance = lazy(() => import('./components/TypeOfInsurance'));

const containerStyle = css`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 1.5rem 1rem 0 1rem;
  height: 100%;
  @media (min-width: 1024px) {
    min-height: calc(100vh - 7rem);
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    min-height: calc(100vh - 4.625rem);
  }
`;

const motorSteppers = {
  [VAN]: () => vanStepper(),
  [MOTOR_FLEET]: (extraCheck) => motorFleetStepper(extraCheck),
  [TRUCK_HGV]: () => truckStepper(),
  [BUS_COACH]: () => busStepper(),
  [MINIBUS]: () => minibusStepper(),
  [COURIER]: (extraCheck) => courierStepper(extraCheck),
  [TAXI]: () => taxiStepper(),
  [MOTOR_TRADE]: () => motorTradeStepper(),
  [HORSEBOX]: () => horseboxStepper(),
  [FOOD_VEHICLE]: (extraCheck) => foodVehicleStepper(extraCheck),
  [AGRICULTURAL_FARM]: (extraCheck) => agriculturalVehicleStepper(extraCheck),
  [EXECUTIVE_CHAUFFEUR]: (extraCheck) => chauffeurStepper(extraCheck),
};

export const AppForm = observer(() => {
  const { step } = stepStore;
  const {
    handleSubmit,
    values: {
      FleetType: fleetType,
      TransportGood: typeOfGood,
      NumberOfVehicle: numberOfVehicles,
      TypeOfCover: typeOfCover,
      NumOfVehicles: numOfVehicles,
      VehiclesToInsure: vehiclesToInsure,
    },
  } = useFormikContext();
  const extraCheck = {
    fleetType,
    numberOfVehicles,
    typeOfGood,
    typeOfCover,
    numOfVehicles,
    vehiclesToInsure,
  };

  const motorStepper = motorSteppers[stateStore.motorType]
    ? motorSteppers[stateStore.motorType](extraCheck)
    : [];

  const stepper = [
    {
      name: 'Policy Disclaimer',
      component: PolicyDisclaimer,
      validation: validationScheme.policyDisclaimer,
    },
    {
      name: 'Type Of Insurance',
      component: TypeOfInsurance,
      validation: validationScheme.typeOfInsurance,
    },
    ...motorStepper,
  ];

  const Component = stepper[step].component;
  return (
    <section className={containerStyle}>
      <Suspense fallback={<Loader message="Please wait..." />}>
        <Component
          handlePreviousStep={() => {
            stepStore.previousStep();
          }}
          handleNextStep={handleSubmit}
          setStepper={(insuranceType, extraValue) => {
            const extra = { ...extraCheck, ...extraValue };
            const motorStepperTemp = motorSteppers[insuranceType](extra);
            const stepperTemp = [
              {
                name: 'Policy Disclaimer',
                component: PolicyDisclaimer,
                validation: validationScheme.policyDisclaimer,
              },
              {
                name: 'Type Of Insurance',
                component: TypeOfInsurance,
                validation: validationScheme.typeOfInsurance,
              },
              ...motorStepperTemp,
            ];
            stateStore.setStepper(stepperTemp);
          }}
        />
      </Suspense>
    </section>
  );
});
