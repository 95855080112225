import React from 'react';
import { css, cx } from '@emotion/css';
import { Link, useNavigate } from 'react-router-dom';
import insyncLogo from '../../assets/Insync-Logo-New.png';
import errorIcon from '../../assets/errorIcon.svg';
import { Footer } from '../components/Footer';
import { ButtonContainer } from '../components/ButtonContainer';
import { Button } from '../components/Button';
import { stepStore } from '../../store/step-store';
import { stateStore } from '../../store/state-store';
import { salesforceStore } from '../../store/salesforce-store';

const containerStyle = css`
  display: grid;
  grid-template-columns: 100%;
  //grid-template-rows: 80% 18rem;
  > footer {
    grid-area: unset;
  }
`;

const bodyContainer = css`
  padding: 2.5rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 1rem);
  > a {
    margin-right: auto;
  }
  @media (max-width: 768px) {
    padding: 1.5rem 1rem;
  }
`;

const singUpContainer = css`
  width: 60%;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const logoStyle = css`
  height: 3.625rem;
  width: 9.375rem;
  @media (max-width: 768px) {
    height: 2.25rem;
    width: auto;
  }
`;
const titleStyle = css`
  font-weight: 900;
  font-size: 4rem;
  line-height: 1.2em;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--primaryBrand);
  margin-bottom: 1.5rem;
  @media (max-width: 768px) {
    font-size: 2.25rem;
  }
`;

const registerLinkTextStyle = css`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.2em;
  letter-spacing: -0.03em;
  color: var(--dark);
  text-align: center;
`;

const linkStyle = css`
  color: var(--dark);
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:hover {
    text-decoration: none;
    color: var(--dark);
  }
  &:hover:after {
    opacity: 1;
    width: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    height: 0.125rem;
    left: 50%;
    width: 0;
    opacity: 0;
    background: var(--primaryBrand);
    transform: translateX(-50%);
    transition: width 0.25s, opacity 0.25s;
  }
`;

const errorIconStyle = css`
  width: 8rem;
  height: 8rem;
`;

const oneColumnStyle = css`
  grid-template-columns: 100% !important;
  width: 100%;
  justify-content: center;
`;

const ErrorScreen = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    stepStore.step = window.innerWidth < 768 ? 0 : 1;
    if (salesforceStore.salesforceOpportunityId) {
      navigate(`/opportunity/${salesforceStore.salesforceOpportunityId}`, {
        replace: true,
      });
    } else if (salesforceStore.salesforceLeadId) {
      navigate(`/lead/${salesforceStore.salesforceLeadId}`, { replace: true });
    } else {
      navigate('/', { replace: true });
    }
    stepStore.step = window.innerWidth < 768 ? 0 : 1;
    navigate(0);
  };
  return (
    <div className={containerStyle}>
      <div className={bodyContainer}>
        <Link to="/" aria-label="Insync logo">
          <img src={insyncLogo} alt="Insyn logo" className={logoStyle} />
        </Link>
        <div className={singUpContainer}>
          <img src={errorIcon} alt="error" className={errorIconStyle} />
          <h1 className={titleStyle}>An error has occurred</h1>
          <div className={registerLinkTextStyle}>
            {`${
              stateStore.apiError ||
              'Unfortunately an error has occurred. Please try again later.'
            } To try
          again please click `}
            <Link
              className={cx(registerLinkTextStyle, linkStyle)}
              to="/"
              onClick={() => window.scrollTo(0, 0)}
            >
              here
            </Link>
            .
          </div>
        </div>
        <ButtonContainer customAlignment={oneColumnStyle}>
          <Button handleClick={handleGoBack} label="Back to Home" />
        </ButtonContainer>
      </div>
      <Footer />
    </div>
  );
};
export default ErrorScreen;
