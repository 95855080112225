import { lazy } from 'react';
import { validationScheme } from '../../../validation/validationScheme';

const AboutYourHorsebox = lazy(() =>
  import('../components/horsebox/AboutYourHorsebox')
);
const Capacity = lazy(() => import('../components/horsebox/Capacity'));
const HorseboxDriver = lazy(() =>
  import('../components/horsebox/HorseboxDriver')
);
const HorseboxExperience = lazy(() =>
  import('../components/horsebox/HorseboxExperience')
);
const HorseboxClaims = lazy(() =>
  import('../components/horsebox/HorseboxClaims')
);
const RegistrationNumber = lazy(() =>
  import('../components/RegistrationNumber')
);
const PersonalInfo = lazy(() => import('../components/PersonalInfo'));
const CoverStartDate = lazy(() => import('../components/CoverStartDate'));

export const horseboxStepper = () => [
  {
    name: 'Horsebox Registration',
    component: RegistrationNumber,
    validation: validationScheme.registrationNumber,
  },
  {
    name: 'About Your Vehicle',
    component: AboutYourHorsebox,
    validation: validationScheme.aboutYourHorsebox,
  },
  {
    name: 'Capacity',
    component: Capacity,
    validation: validationScheme.capacity,
  },
  {
    name: 'Horsebox Driver',
    component: HorseboxDriver,
    validation: validationScheme.horseboxDriver,
  },
  {
    name: 'Horsebox Experience',
    component: HorseboxExperience,
    validation: validationScheme.horseboxExperience,
  },
  {
    name: 'Horsebox Claims',
    component: HorseboxClaims,
    validation: validationScheme.horseboxClaims,
  },
  {
    name: 'Personal Info',
    component: PersonalInfo,
    validation: validationScheme.personalInfo,
  },
  {
    name: 'Cover Start Date',
    component: CoverStartDate,
    validation: validationScheme.coverStartDate,
    action: 'savePolicy',
  },
];
