import { makeAutoObservable } from 'mobx';
import { vanStepper } from '../components/AppForm/stepper/vanStepper';
import { validationScheme } from '../validation/validationScheme';

export class StateStore {
  apiError = null;

  stepper = [
    {
      name: 'Policy Disclaimer',
      validation: validationScheme.policyDisclaimer,
    },
    {
      name: 'Type Of Insurance',
      validation: validationScheme.typeOfInsurance,
    },
    ...vanStepper(),
  ];

  motorType = '';

  constructor() {
    makeAutoObservable(this);
  }

  setLoader(isSubmitting) {
    this.isSubmitting = isSubmitting;
  }

  setStepper(stepper) {
    this.stepper = stepper;
  }
  setMotorType = (motorType) => {
    this.motorType = motorType;
  };
}

export const stateStore = new StateStore();
