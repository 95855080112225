import React from 'react';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import { useFormikContext } from 'formik';
import { useQuoteInfoFromOpportunityID } from '../../api/api';
import { Loader } from '../components/Loader';
import { AppForm } from '../AppForm/AppForm';
import { salesforceStore } from '../../store/salesforce-store';

export const OpportunityWrapper = () => {
  const { id } = useParams();
  const { isLoading: isLoadingQuote } = useQuoteInfoFromOpportunityID(id);
  const formik = useFormikContext();
  if (isLoadingQuote) {
    return <Loader message="Loading your quote..." />;
  }
  if (!get(formik, 'values.Policyholder.Email')) {
    salesforceStore.saveDataToFormik(formik);
  }

  return <AppForm />;
};
