import get from 'lodash/get';
import { observable } from 'mobx';

export class StateStore {
  salesforceLeadId = undefined;
  salesforceOpportunityId = undefined;
  salesforceContactId = undefined;
  firstName = '';
  lastName = '';
  email = '';
  phone = '';
  quoteRef = '';

  @observable
  created = false;

  @observable
  rateLoader = false;

  @observable
  isSalesforceWorking = true;

  saveSalesforceData = (quote) => {
    this.firstName = get(quote, 'firstName');
    this.lastName = get(quote, 'lastName');
    this.email = get(quote, 'email');
    this.phone = get(quote, 'phone');
    this.quoteRef = quote.quoteRef;
    this.salesforceLeadId = quote.leadId;
    this.salesforceContactId = quote.contactId;
    this.salesforceOpportunityId = quote.opportunityId;
    this.setCreated(true);
  };

  saveDataToFormik = (formik) => {
    const { setValues, values } = formik;
    const updateData = {
      firstName: this.firstName || values.firstName,
      lastName: this.lastName || values.lastName,
      email: this.email || values.email,
      phone: this.phone || values.phone,
    };

    setValues({
      ...values,
      ...updateData,
    });
    setValues({
      ...values,
      Policyholder: {
        Name: `${this.firstName || values.Policyholder?.Name || ''} ${
          this.lastName || ''
        }`,
        Email: this.email || values.Policyholder?.Email,
        Phone: this.phone || values.Policyholder?.Phone,
      },
    });
  };
  getCreated = () => {
    return this.created;
  };
  setCreated = (value) => {
    this.created = value;
  };
  getIsSalesforceWorking = () => {
    return this.isSalesforceWorking;
  };
  setIsSalesforceWorking = (value) => {
    this.isSalesforceWorking = value;
  };
  getRateLoader = () => {
    return this.rateLoader;
  };
}

export const salesforceStore = new StateStore();
