import { lazy } from 'react';
import { validationScheme } from '../../../validation/validationScheme';
import { motorFleetStepper } from './motorFleetStepper';

const PersonalInfo = lazy(() => import('../components/PersonalInfo'));
const CoverStartDate = lazy(() => import('../components/CoverStartDate'));
const TypeOfCover = lazy(() => import('../components/TypeOfCover'));
const DeliveryCompany = lazy(() =>
  import('../components/courier/DeliveryCompany')
);
const WorkTime = lazy(() => import('../components/courier/WorkTime'));
const CourierGoods = lazy(() => import('../components/courier/CourierGoods'));
const AboutCourierVehicle = lazy(() =>
  import('../components/courier/AboutCourierVehicle')
);
const NumberOfVehicles = lazy(() =>
  import('../components/courier/NumberOfVehicles')
);
const MotoringConviction = lazy(() =>
  import('../components/courier/MotoringConviction')
);
const AnyClaim = lazy(() => import('../components/courier/AnyClaim'));
const CourierDrivingLicenceDriver = lazy(() =>
  import('../components/courier/CourierDrivingLicenceDriver')
);
const AboutCourierTransport = lazy(() =>
  import('../components/courier/AboutCourierTransport')
);
const CourierVehicleDriver = lazy(() =>
  import('../components/courier/CourierVehicleDriver')
);
const NoPrivateCarClaimBonus = lazy(() =>
  import('../components/courier/NoPrivateCarClaimBonus')
);
const NoCourierClaimBonus = lazy(() =>
  import('../components/courier/NoCourierClaimBonus')
);
const NoFastFoodClaimBonus = lazy(() =>
  import('../components/courier/NoFastFoodClaimBonus')
);
const RegistrationNumber = lazy(() =>
  import('../components/RegistrationNumber')
);

export const courierStepper = (extraCheck) => [
  {
    name: 'Number of vehicles',
    component: NumberOfVehicles,
    validation: validationScheme.numberOfVehicles,
  },
  ...(extraCheck.numberOfVehicles === '1 Vehicle'
    ? [
        {
          name: 'Courier Registration',
          component: RegistrationNumber,
          validation: validationScheme.registrationNumber,
        },
        {
          name: 'About Vehicle',
          component: AboutCourierVehicle,
          validation: validationScheme.courierVehicle,
        },
        {
          name: 'Type of goods',
          component: CourierGoods,
          validation: validationScheme.courierGoods,
        },

        ...(extraCheck.typeOfGood === 'Fast Food / Takeaway Food'
          ? [
              {
                name: 'Work time',
                component: WorkTime,
                validation: validationScheme.workTime,
              },
              {
                name: 'No Fast food Claim',
                component: NoFastFoodClaimBonus,
                validation: validationScheme.noFastFoodClaimBonus,
              },
              {
                name: 'Delivery company',
                component: DeliveryCompany,
                validation: validationScheme.deliverCompany,
              },
            ]
          : [
              {
                name: 'No Courier Claim',
                component: NoCourierClaimBonus,
                validation: validationScheme.noCourierClaimBonus,
              },
              {
                name: 'No Private Car Claim',
                component: NoPrivateCarClaimBonus,
                validation: validationScheme.noPrivateCarClaimBonus,
              },
              {
                name: 'Type of Cover',
                component: TypeOfCover,
                validation: validationScheme.typeOfCover,
              },
              {
                name: 'Driver Type',
                component: CourierVehicleDriver,
                validation: validationScheme.fleetDriverType,
              },
              {
                name: 'Driving licence Type',
                component: CourierDrivingLicenceDriver,
                validation: validationScheme.courierDrivingLicence,
              },
              {
                name: 'About transport',
                component: AboutCourierTransport,
                validation: validationScheme.aboutCourierTransport,
              },
              {
                name: 'Claim info',
                component: AnyClaim,
                validation: validationScheme.anyClaims,
              },
              {
                name: 'Motoring Conviction',
                component: MotoringConviction,
                validation: validationScheme.motoringConviction,
              },
            ]),
        {
          name: 'Personal Info',
          component: PersonalInfo,
          validation: validationScheme.personalInfo,
        },
        {
          name: 'Cover Start Date',
          component: CoverStartDate,
          validation: validationScheme.coverStartDate,
          action: 'savePolicy',
        },
      ]
    : motorFleetStepper(extraCheck)),
];
