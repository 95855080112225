import React from 'react';
import { css } from '@emotion/css';
import downImg from '../../assets/cleaningMonitor.svg';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

const containerStyle = css`
  width: 100%;
  grid-area: main;
  @media (min-width: 769px) and (max-width: 1024px) {
    min-height: calc(100vh - 5.625rem);
  }
  min-height: calc(100vh - 7rem);
  max-height: 100vh;
  text-align: center;
  margin-top: 6.25rem;
`;

const imgStyle = css`
  height: 12.5rem;
  margin-bottom: 3.125rem;
`;

const subtitle = css`
  color: var(--gray);
  margin-top: 1.25rem;
  font-weight: unset;
`;

const Maintenance = () => (
  <>
    <Header />
    <div className={containerStyle}>
      <img src={downImg} alt="down for maintenance" className={imgStyle} />
      <h1>Our website is temporarily offline</h1>
      <h4 className={subtitle}>
        The site it down for maintenance. Please check back soon.
      </h4>
    </div>
    <Footer />
  </>
);
export default Maintenance;
