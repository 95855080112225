import { lazy } from 'react';
import { validationScheme } from '../../../validation/validationScheme';

const TypeOfTruckVehicle = lazy(() =>
  import('../components/truck/TypeOfTruckVehicle')
);
const AboutTruck = lazy(() => import('../components/truck/AboutTruck'));
const AboutTruckTransport = lazy(() =>
  import('../components/truck/AboutTruckTransport')
);
const DrivingLicenceInfo = lazy(() =>
  import('../components/truck/DrivingLicenceInfo')
);
const TruckDriver = lazy(() => import('../components/truck/TruckDriver'));
const YearsOfNoClaim = lazy(() => import('../components/truck/YearsOfNoClaim'));
const TypeOfCover = lazy(() => import('../components/TypeOfCover'));
const PersonalInfo = lazy(() => import('../components/PersonalInfo'));
const CoverStartDate = lazy(() => import('../components/CoverStartDate'));
const RegistrationNumber = lazy(() =>
  import('../components/RegistrationNumber')
);

export const truckStepper = () => [
  {
    name: 'Type of Vehicle',
    component: TypeOfTruckVehicle,
    validation: validationScheme.truckType,
  },
  {
    name: 'Truck Registration',
    component: RegistrationNumber,
    validation: validationScheme.registrationNumber,
  },
  {
    name: 'About your vehicle',
    component: AboutTruck,
    validation: validationScheme.aboutTruck,
  },
  {
    name: 'Driving Licence',
    component: DrivingLicenceInfo,
    validation: validationScheme.drivingLicenceInfo,
  },
  {
    name: 'Type of Cover',
    component: TypeOfCover,
    validation: validationScheme.typeOfCover,
  },
  {
    name: 'Driver Type',
    component: TruckDriver,
    validation: validationScheme.fleetDriverType,
  },
  {
    name: 'Claim info',
    component: YearsOfNoClaim,
    validation: validationScheme.noClaimInfo,
  },
  {
    name: 'About Truck Transport',
    component: AboutTruckTransport,
    validation: validationScheme.aboutTruckTransport,
  },
  {
    name: 'Personal Info',
    component: PersonalInfo,
    validation: validationScheme.personalInfo,
  },
  {
    name: 'Cover Start Date',
    component: CoverStartDate,
    validation: validationScheme.coverStartDate,
    action: 'savePolicy',
  },
];
