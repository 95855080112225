import React from 'react';
import './index.css';
import { Route, Routes } from 'react-router-dom';
import SuccessScreen from './components/Success/SuccessPage';
import Maintenance from './components/Maintenance/Maintenance';
import ErrorScreen from './components/Error/ErrorScreen';
import SalesforceDetails from './components/Salesforce/SalesforceDetails';
import { FormikWrapper } from './components/Main/FormikWrapper';
import { LeadWrapper } from './components/Main/LeadWrapper';
import { OpportunityWrapper } from './components/Main/OpportunityWrapper';
import { AppForm } from './components/AppForm/AppForm';

export const App = () => (
  <Routes>
    <Route exact path="/" element={<SalesforceDetails />} />
    <Route exact path="/error" element={<ErrorScreen />} />
    <Route exact path="/success" element={<SuccessScreen />} />
    <Route path="/maintenance" element={<Maintenance />} />
    <Route path="/" element={<FormikWrapper />}>
      <Route exact path="/lead/:id" element={<LeadWrapper />} />
      <Route exact path="/opportunity/:id" element={<OpportunityWrapper />} />
      <Route path="/form" element={<AppForm />} />
    </Route>
  </Routes>
);
