import { lazy } from 'react';
import { validationScheme } from '../../../validation/validationScheme';

const TypeOfInsurance = lazy(() =>
  import('../components/executiveChauffeur/TypeOfInsurance')
);
const VehiclesToInsure = lazy(() =>
  import('../components/executiveChauffeur/VehiclesToInsure')
);
const AboutYourVehicle = lazy(() =>
  import('../components/executiveChauffeur/AboutYourVehicle')
);
const MaxPassengers = lazy(() =>
  import('../components/executiveChauffeur/MaxPassengers')
);
const StretchVehicle = lazy(() =>
  import('../components/executiveChauffeur/StretchVehicle')
);
const TypeOfCover = lazy(() => import('../components/TypeOfCover'));
const VehicleDriver = lazy(() =>
  import('../components/executiveChauffeur/VehicleDriver')
);
const AdditionalInfo = lazy(() =>
  import('../components/executiveChauffeur/AdditionalInfo')
);
const PersonalInfo = lazy(() => import('../components/PersonalInfo'));
const CoverStartDate = lazy(() => import('../components/CoverStartDate'));
const RegistrationNumber = lazy(() =>
  import('../components/RegistrationNumber')
);

export const chauffeurStepper = (extraCheck) => [
  {
    name: 'Type of Insurance',
    component: TypeOfInsurance,
    validation: validationScheme.typeOfChauffeurInsurance,
  },
  {
    name: 'Vehicles To Insure',
    component: VehiclesToInsure,
    validation: validationScheme.vehiclesToInsure,
  },
  ...(extraCheck.vehiclesToInsure === '1 Vehicle'
    ? [
        {
          name: 'Vehicle Registration',
          component: RegistrationNumber,
          validation: validationScheme.registrationNumber,
        },
        {
          name: 'About Your Vehicle',
          component: AboutYourVehicle,
          validation: validationScheme.aboutChauffeurVehicle,
        },
        {
          name: 'Maximum Passengers',
          component: MaxPassengers,
          validation: validationScheme.maxChauffeurPassengers,
        },
      ]
    : [
        {
          name: 'Stretch Vehicle',
          component: StretchVehicle,
          validation: validationScheme.stretchVehicle,
        },
        {
          name: 'Type of Cover',
          component: TypeOfCover,
          validation: validationScheme.typeOfCover,
        },
        {
          name: 'Vehicle Drivers',
          component: VehicleDriver,
          validation: validationScheme.vehicleDriver,
        },
        {
          name: 'Additional Info',
          component: AdditionalInfo,
          validation: validationScheme.additionalChauffeurInfo,
        },
      ]),

  {
    name: 'Personal Info',
    component: PersonalInfo,
    validation: validationScheme.personalInfo,
  },
  {
    name: 'Cover Start Date',
    component: CoverStartDate,
    validation: validationScheme.coverStartDate,
    action: 'savePolicy',
  },
];
