import React from 'react';
import { css } from '@emotion/css';
import insyncLogo from '../../assets/Insync-Logo-New.png';
import facebookUrl from '../../assets/facebook.png';
import instagramUrl from '../../assets/instagram.png';

const footer = css`
  grid-area: footer;
  background: rgba(36, 36, 39, 0.04);
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const infoContainerStyle = css`
  width: 15rem;
  height: 100%;
  padding: 3rem 2rem;
`;

const textContainerStyle = css`
  flex: 1;
  color: var(--dark);
  padding: 3rem;
  align-self: flex-end;
  font-size: 0.75rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const socialMediaContainerStyle = css`
  height: 100%;
  padding: 3rem 2rem 3rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

const imgStyle = css`
  height: 2.5rem;
  width: 5.95rem;
`;

const infoTextStyle = css`
  margin-top: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  color: var(--dark);
`;

const socialMediaUrlStyle = css`
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
`;

const linkStyle = css`
  color: var(--dark);
  &:hover {
    color: var(--dark);
    text-decoration: none;
  }
`;

export const Footer = () => (
  <footer className={footer}>
    <div className={infoContainerStyle}>
      <img src={insyncLogo} alt="logo" className={imgStyle} />
      <p className={infoTextStyle}>
        Insync Insurance Solutions Ltd <br />9 Albany Park, Cabot Lane Poole,
        Dorset BH17 7BX
      </p>
      <p className={infoTextStyle}>
        t. 0330 124 0730 <br />
        e.{' '}
        <a href="mailto:hello@insyncinsurance.co.uk" className={linkStyle}>
          hello@insyncinsurance.co.uk
        </a>
      </p>
    </div>
    <p className={textContainerStyle}>
      © Insync Insurance Solutions Ltd 2018 All rights reserved. Insync
      Insurance Solutions Ltd is authorised & regulated by the Financial Conduct
      Authority where our reference number is 766691. Our registered office is
      7th Floor, Corn Exchange, 55 Mark Lane, London, EC3R 7NE and we are
      registered in England under company number 08810662. Should you have cause
      to complain, and you are not satisfied with our response to your
      complaint, you may be able to refer it to the Financial Ombudsman Service,
      which can be contacted as follows: The Financial Ombudsman Service
      Exchange Tower, London, E14 9SR | Tel: 0800 023 4567 or 01200 309516 |
      www.financial-ombudsman.org.uk |
      <a
        href="https://insyncinsurance.co.uk/insynccentraldocs/centraldocs/Insync%20TOBA.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className={linkStyle}
      >
        Terms of Business
      </a>
    </p>
    <div className={socialMediaContainerStyle}>
      <a
        href="https://en-gb.facebook.com/insyncinsurance/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="facebook" src={facebookUrl} className={socialMediaUrlStyle} />
      </a>
      <a
        href="https://www.instagram.com/insyncinsurance/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt="instagram"
          src={instagramUrl}
          className={socialMediaUrlStyle}
        />
      </a>
    </div>
  </footer>
);
