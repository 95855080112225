import { lazy } from 'react';
import { validationScheme } from '../../../validation/validationScheme';

const RatedFleet = lazy(() => import('../components/fleet/RatedFleet'));
const FleetVehicleDriver = lazy(() =>
  import('../components/fleet/VehicleDriver')
);
const TypeOfFleet = lazy(() => import('../components/fleet/TypeOfFleet'));
const FleetBusinessInfo = lazy(() =>
  import('../components/fleet/FleetBusinessInfo')
);
const FleetVehicles = lazy(() => import('../components/fleet/FleetVehicles'));
const PersonalInfo = lazy(() => import('../components/PersonalInfo'));
const TypeOfCover = lazy(() => import('../components/TypeOfCover'));
const CoverStartDate = lazy(() => import('../components/CoverStartDate'));

export const motorFleetStepper = (extraCheck) => [
  {
    name: 'Type of fleet',
    component: TypeOfFleet,
    validation: validationScheme.typeOfFleet,
  },
  ...(extraCheck.fleetType === 'Business/Commercial'
    ? [
        {
          name: 'Business Info',
          component: FleetBusinessInfo,
          validation: validationScheme.fleetBusinessInfo,
        },
      ]
    : []),
  {
    name: 'Type of Cover',
    component: TypeOfCover,
    validation: validationScheme.typeOfCover,
  },
  {
    name: 'Driver Type',
    component: FleetVehicleDriver,
    validation: validationScheme.fleetDriverType,
  },
  ...(extraCheck.fleetType === 'Business/Commercial'
    ? [
        {
          name: 'Is fleet Rated',
          component: RatedFleet,
          validation: validationScheme.ratedFleet,
        },
      ]
    : []),
  {
    name: 'Vehicles in fleet',
    component: FleetVehicles,
    validation: validationScheme.fleetVehicles,
  },
  {
    name: 'Personal Info',
    component: PersonalInfo,
    validation: validationScheme.personalInfo,
  },
  {
    name: 'Cover Start Date',
    component: CoverStartDate,
    validation: validationScheme.coverStartDate,
    action: 'savePolicy',
  },
];
