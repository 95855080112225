import { makeObservable, observable, action } from 'mobx';

export class StepStore {
  @observable
  step = window.innerWidth < 768 ? 0 : 1;

  @observable
  previousStepValue = 0;

  constructor() {
    makeObservable(this);
  }

  @action
  nextStep = () => {
    this.previousStepValue = this.step;
    this.step++;
  };

  @action
  previousStep = () => this.step--;

  @action
  skipNSteps = (n) => {
    this.previousStepValue = this.step;
    this.step = this.step + n;
  };
  @action
  returnNSteps = (n) => (this.step = this.step - n);

  @action
  goToStep = (n) => {
    this.previousStepValue = this.step;
    this.step = n;
  };
}

export const stepStore = new StepStore();
